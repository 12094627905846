body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}

p,
ul {
  margin: 0;
}

a {
  color: #76aad8;
  text-decoration: none;
}
